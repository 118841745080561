export default {
    nav: {
        main: {
            assets: 'Stamgegevens',
            planning: 'Planning',
            orders: 'Orders',
        },
        assets: {
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            emailTemplates: 'Email sjablonen',
            customers: 'Klanten',
            crops: 'Planten',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        planning: {
            packagingLines: 'Verpakkingslijnen',
            main: 'Planning',
            salesOrders: 'Orders',
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe Gebruiker',
        },
        create: {
            title: 'Gebruiker Aanmaken',
        },
        edit: {
            title: 'Gebruiker Aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                },
                empty: 'Geen groepen toegekend',
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}', },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord Vergeten',
            requestButton: 'Verstuur Email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord Aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord Veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore:'Herstel',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        no: 'Nee',
        yes: 'Ja',
        either: 'Beide',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    emailTemplate: {
        overview: {
            title: 'Email sjablonen' ,
            addButton: 'Nieuw sjabloon',
        },
        create: { title: 'Mail sjabloon aanmaken' },
        edit: { title: 'Mail sjabloon aanpassen' },
        add: { title: 'Mail sjabloon toevoegen' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is standaard' },
            name: { label: 'Naam' },
            emailType: { label: 'Email type' },
            subject: { label: 'Onderwerp' },
            textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
            senderEmail: { label: 'Verzender' },
        }
    },
    emailTypes: {
        password_reset: 'Wachtwoord reset',
        user_activation: 'Account activatie',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    color: {
        yellow: 'Geel',
        orange: 'Oranje',
        darkorange: 'Donkeroranje',
        lightred: 'Lichtrood',
        red: 'Rood',
        darkred: 'Donkerrood',
        lightpink: 'Lichtroze',
        pink: 'Roze',
        darkpink: 'Donkerroze',
        lightviolet: 'Lichtviolet',
        violet: 'Violet',
        darkviolet: 'Donkerviolet',
        lightpurple: 'Lichtpaars',
        purple: 'Paars',
        darkpurple: 'Donkerpaars',
        lightindigo: 'Lichtindigo',
        indigo: 'Indigo',
        darkindigo: 'Donkerindigo',
        lightblue: 'Lichtblauw',
        blue: 'Blauw',
        darkblue: 'Donkerblauw',
        lightteal: 'Licht blauwgroen',
        teal: 'Blauwgroen',
        darkteal: 'Donker blauwgroen',
        lightgreen: 'Lichtgroen',
        green: 'Groen',
        darkgreen: 'Donkergroen',
        lightlime: 'Licht geelgroen',
        lime: 'Geelgroen',
        darklime: 'Donker geelgroen',
        lightbrown: 'Lichtbruin',
        brown: 'Bruin',
        darkbrown: 'Donkerbruin',
        lightgrey: 'Lichtgrijs',
        grey: 'Grijs',
        black: 'Zwart',
        none: 'Geen kleur',
    },
    customer: {
        overview: {
            title: 'Klanten',
        },
        edit: {
            title: 'Bewerk klant',
        },
        create: {
            title: 'Maak klant aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            color: {
                label: 'Kleur',
            },
        },
    },
    crop: {
        overview: {
            title: 'Planten',
        },
        edit: {
            title: 'Bewerk plant',
            packagingSizes: {
                addButtonLabel: 'Maak verpakkingsgrootte aan',
                removeButtonLabel: 'Verwijder verpakkingsgrootte',
            },
        },
        create: {
            title: 'Maak plant aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            packagingSizes: {
                label: 'Verpakkingsgroottes'
            },
        },
    },
    packagingSize: {
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            productionAmountPerMinute: {
                label: 'Aantal geproduceerd per minuut',
                shortLabel: 'aantal / min',
            },
            order: {
                label: 'Volgorde',
            },
        },
    },
    packagingLine: {
        overview: {
            title: 'Verpakkingslijnen',
        },
        edit: {
            title: 'Bewerk verpakkingslijn',
        },
        create: {
            title: 'Maak verpakkingslijn aan',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            customer: {
                label: 'Klant',
            },
        },
    },
    salesOrder: {
        overview: {
            title: 'Orders',
            productionOrderProgress: {
                label: 'Voltooide artikelen',
                filterLabel: 'Voltooid',
                options: {
                    yes: 'Voltooid',
                    no: 'Niet voltooid',
                    both: 'Beide',
                },
            },
        },
        edit: {
            title: 'Bewerk order',
            pickupTimes: {
                addButtonLabel: 'Voeg pick-up tijd toe',
                removeButtonLabel: 'Verwijder pick-up tijd',
            },
            productionOrders: {
                addButtonLabel: 'Voeg artikel toe',
                removeButtonLabel: 'Verwijder artikel',
            },
        },
        create: {
            title: 'Nieuwe order',
        },
        field: {
            id: {
                label: 'ID',
            },
            customer: {
                label: 'Klant',
            },
            date: {
                label: 'Datum',
            },
            productionOrders: {
                label: 'Artikelen',
            },
            pickupTimes: {
                label: 'Pick-up tijden',
            },
            orderDocuments: {
                label: 'Order documenten'
            },
            remarks: {
                label: 'Notities',
            },
        },
    },
    planning: {
        overview: {
            salesOrders: {
                createButton: 'Nieuwe order',
                editButton: 'Bewerk',
                deleteConfirmation: 'Dit zal de sales order voor eeuwig verwijderen (een hele lange tijd). Weet je het zeker?',
            },
        },
    },
    document: {
        overview: {
            title: 'Documenten',
            addButton: 'Document',
            downloadButton: 'Download'
        },
        edit: {
            title: 'Document bewerken',
        },
        create: {
            title: 'Document aanmaken',
        },
        field: {
            id: {
                label: 'Document nr.'
            },
            type: {
                label: 'Bestandsformaat',
            },
            file: {
                label: 'Bestand',
            },
            path: {
                label: 'Path',
            },
        },
        preview: {
            noFileToPreview: 'Geen preview beschikbaar',
            openFilePreview: 'Open bestandspreview',
            closeFilePreview: 'Bestandspreview sluiten',
        },
        input: {
            upload: {
                label: 'Bestand uploaden',
            },
            download: {
                label: 'Bestand downloaden'
            },
        },
    },
};
